import i18next from 'i18next';
import moment from 'moment';

export const LANGUAGE_CONFIG = ['en', 'fr', 'es', 'nl'];

export const COUNTRY_CONFIG = () =>
  ['IT', 'FR', 'BE', 'MX'].map((country) => ({
    label: i18next.t(`country-${country}`),
    value: country,
  }));

export const USER_MARKET_TREND_FIRST_VALID_DATE = () => moment().add(30, 'days').format('YYYY-MM-DD');
