import React from 'react';

import { LANGUAGE_CONFIG } from '../../config/app-config';
import { useUserInfo } from '../../context/UserInfoContext';
import { useUpdateUserLanguage } from '../../endpoints/user/useUpdateUserLanguage';
import { SelectLangStyled } from './SelectLang.styled';

const SelectLang = ({ isDesktop }) => {
  const { userInfo } = useUserInfo();
  const { mutate: updateLang } = useUpdateUserLanguage();

  const handleChange = React.useCallback(
    (selectedValue) => {
      updateLang(selectedValue);
    },
    [updateLang],
  );

  return (
    <SelectLangStyled
      bordered={false}
      desktop={isDesktop.toString()}
      items={LANGUAGE_CONFIG.map((lang) => ({ value: lang, label: lang.toUpperCase() }))}
      size="small"
      value={userInfo.language}
      onChange={handleChange}
    />
  );
};

export default SelectLang;
